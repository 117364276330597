import styles from "./Hero.module.scss";
const HeroLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="150"
      height="150"
      viewBox="500 190 285 415"
      xmlSpace="default"
      className={styles.heroLogo}
    >
      <g paintOrder="stroke">
        <path
          fill="#FEB139"
          d="M20.82499999999999 4.094999999999999L15.86499999999998 -4.094999999999999 -20.825000000000017 -4.094999999999999 -15.865000000000009 4.094999999999999z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -21.142 -82.803)"
        ></path>
        <path
          fill="#FEB139"
          d="M-16.47 3.094999999999999L20.22 3.094999999999999 16.480000000000018 -3.094999999999999 -20.22 -3.094999999999999z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -15.811 -73.985)"
        ></path>
        <path
          fill="#D61C4E"
          d="M288.34 288.73a76.388 76.388 0 0020.3-27.1l-54.79-90.59h-36.69l71.18 117.69z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 11.215 -17.513) translate(-262.9 -229.885)"
        ></path>
        <path
          fill="#FEB139"
          d="M-16.47 3.0999999999999943L20.22 3.0999999999999943 16.47 -3.1000000000000227 -20.22 -3.1000000000000227z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -67.523 -91.624)"
        ></path>
        <path
          fill="#FEB139"
          d="M20.954999999999984 4.314999999999998L15.734999999999985 -4.314999999999998 -20.955000000000013 -4.314999999999998 -15.735000000000014 4.314999999999998z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -72.976 -100.629)"
        ></path>
        <path
          fill="#D61C4E"
          d="M251.58 305.83a75.989 75.989 0 0029.62-11.69l-86.76-143.36h-36.69l93.83 155.05z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -26.583 -18.888) translate(-219.475 -228.305)"
        ></path>
        <path
          fill="#FEB139"
          d="M15.77000000000001 -4.27000000000001L-20.929999999999993 -4.27000000000001 -15.759999999999991 4.269999999999982 20.930000000000007 4.269999999999982z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -88.439 -59.367)"
        ></path>
        <path
          fill="#FEB139"
          d="M16.480000000000018 -3.094999999999999L-20.22 -3.094999999999999 -16.47 3.094999999999999 20.220000000000027 3.094999999999999z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -83.016 -50.397)"
        ></path>
        <path
          fill="#D61C4E"
          d="M198.76 295.37c11.68 7.29 25.47 11.5 40.25 11.5 1.12 0 2.24-.04 3.35-.08l-65.72-108.66h-36.69l58.81 97.24z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 -51.233 2.172) translate(-191.155 -252.5)"
        ></path>
        <path
          fill="#293462"
          d="M365.81 219.12l-17.82-3.81a107.443 107.443 0 00-13.39-32.22l9.93-15.33c2.2-3.39 2.06-7.43-.34-9.83l-19.44-19.44c-2.39-2.39-6.44-2.53-9.83-.34l-15.33 9.93c-4.31-2.68-8.83-5.05-13.51-7.11l-25.11 25.11c22.61 5.61 41.26 21.34 50.86 42.08-.02-.06-.03-.11-.05-.17a75.96 75.96 0 017.07 32.05c0 10.27-2.04 20.06-5.73 29-4.43 10.76-11.26 20.28-19.81 27.91a77.201 77.201 0 01-7.04 5.57 75.84 75.84 0 01-29.21 12.36c-2.97.57-6.01.96-9.1 1.17-1.76.12-3.53.21-5.32.21-13.46 0-26.1-3.5-37.08-9.63-16.91-9.43-29.86-25.11-35.71-43.92.05.11.11.22.16.33-.49-1.53-.94-3.08-1.33-4.66l-25.11 25.11c2.06 4.68 4.43 9.2 7.11 13.51l-9.93 15.33c-2.2 3.39-2.06 7.43.34 9.83l19.44 19.44c2.39 2.39 6.44 2.53 9.83.34l15.33-9.93c9.81 6.1 20.66 10.68 32.22 13.39l3.81 17.82c.84 3.95 3.8 6.71 7.19 6.71h27.49c3.39 0 6.34-2.76 7.19-6.71l3.81-17.82c11.56-2.71 22.41-7.29 32.22-13.39l15.33 9.93c3.39 2.2 7.43 2.06 9.83-.34l19.44-19.44c2.39-2.39 2.53-6.44.34-9.83L334.63 297c6.1-9.81 10.68-20.66 13.39-32.22l17.82-3.81c3.95-.84 6.71-3.8 6.71-7.19V226.3c-.03-3.38-2.79-6.34-6.74-7.18z"
          transform="matrix(1.92115 0 0 1.92115 640 392.964) matrix(.87042 0 0 .87042 5.096 2.837) translate(-255.87 -253.264)"
        ></path>
      </g>
    </svg>
  );
}

export default HeroLogo;
